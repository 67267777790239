import "./navbar.scss";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import Avatar from "react-avatar";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect, useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom"

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const [adminName,setAdminName] = useState("");
  const [shouldFullScree, setShouldFullScreen] = useState(false);
  const navigate = useNavigate();
  function toggleFullScreen() {
    // This gives the whole Dom or html Doc
    const elem = document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // Request fullscreen
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  }

  function logoutHanlder(){
    localStorage.clear("auth");
    navigate("/login");
  }


  useEffect(()=>{
    const authName = JSON.parse(window.localStorage.getItem("auth")).name;
      setAdminName(authName)
  },[])

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <Avatar id="avatarimg" name={adminName} size="30px" round />
            <span style={{marginLeft:"10px"}}>Admin &gt; {adminName}</span>
          </div>
          {/* <div className="admin_panel">
              <h3 style={{textTransform:"uppercase",letterSpacing:"1px"}}>Admin Panel</h3>
          </div> */}

          <div className="item">
           <button onClick={logoutHanlder}> <LogoutIcon style={{marginRight:"5px"}} /> Logout</button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Navbar;

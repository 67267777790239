import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import "./sample-papers.scss";
import { useLocation } from "react-router-dom";

const SamplePaper = (props) => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  console.log(location.state.questions);
  

  useEffect(() => {
    setQuestions(location.state.questions);
  }, []);





  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h1>Paper</h1>
            <div className="questions" >
              <table>
                <thead>
                  <tr>
                    <th>Qno</th>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.length
                    ? questions.map((question, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{question.question}</td>
                            <td>{question.answer}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamplePaper;

import React, { useEffect, useState } from "react";
import "./issues.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Issues = () => {
  const [allStudent, setAllStudents] = useState("");
  const [studentsWithIssues, setStudentsWithIssues] = useState("");
  const [backdropState, setBackdropState] = useState(false);

  async function getAllStudent() {
    try {
      setBackdropState(true);
      const students = await axios(
        "https://api.ascentabacus.com/get-all-students"
      );

      if (students.data.students) {
        const stuWithIssues = students.data.students.filter(
          (student, index) => {
            return student.raisedIssues.length > 0;
          }
        );
        if (stuWithIssues.length > 0) {
          setStudentsWithIssues(stuWithIssues);
        }
        setBackdropState(false);

        setAllStudents(students.data.student);
        return;
      }
    } catch (error) {
      console.log(error.message);
      setBackdropState(false);

      toast.error("Something went wrong! Please try after sometime.");
    }
  }

  useEffect(() => {
    getAllStudent();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>
              &nbsp; &gt; &nbsp;
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                All Issues
              </span>
            </h6>

            <div className="all_issue">
              <Table>
                <thead>
                  <tr className="text-center">
                    <th>Sr.No.</th>
                    <th>Student Name</th>
                    <th>Issue Title</th>
                    <th>Issue Desription</th>
                    <th>Steps To Reproduce Issue</th>
                    <th>Issue Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {studentsWithIssues?.length
                    ? studentsWithIssues.map((student, index) => {
                        return (
                          <tr className="text-center">
                            <td>{index + 1}</td>
                            <td>
                              {student?.firstname} {student?.lastname}
                            </td>
                            <td>
                              {
                                student?.raisedIssues[
                                  student?.raisedIssues?.length - 1
                                ].issueTitle
                              }
                            </td>
                            <td>
                              {
                                student?.raisedIssues[
                                  student?.raisedIssues?.length - 1
                                ].issueDescription
                              }
                            </td>
                            <td>
                              {
                                student?.raisedIssues[
                                  student?.raisedIssues?.length - 1
                                ].stepsToReproduceIssue
                              }
                            </td>
                            <td>
                              <img
                              style={{width:"40px",height:"40px"}}
                                src={
                                  student?.raisedIssues[
                                    student?.raisedIssues?.length - 1
                                  ].issueImageLink
                                }
                              />
                            </td>
                            <td>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  background: "blue",
                                  color: "white",
                                  padding: "5px 10px",
                                }}
                                to={`/student/issue/${student._id}`}
                              >
                                View All issues
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    : "No Issue raised so far."}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Issues;

import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import "./students.scss";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TablePagination from "@mui/material/TablePagination";

import * as XLSX from "xlsx";

const Students = () => {
  const [students, setStudents] = useState([]);
  const [prevStudents, setPrevStudents] = useState([]);
  const [name, setName] = useState("");
  const [center, setCenter] = useState("");
  const [filteredStudents, setFilteredStduents] = useState([]);

  const [backdropState, setBackdropState] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchLevel, setSearchLevel] = useState("");

  const [docCount, setDocCount] = useState();
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(10);
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();

  // async function getAllStudents() {
  //   const allStudents = await axios.get(
  //     "/get-all-students"
  //   );

  //   if (allStudents.data.students) {
  //     const newestFirst = allStudents?.data?.students.reverse();

  //     setBackdropState(false);
  //     setStudents(newestFirst);
  //     setPrevStudents(newestFirst);
  //     setFilteredStduents(newestFirst);
  //   } else {
  //     alert("something went wrong");
  //     setBackdropState(false);
  //   }
  // }

  function searchByName(searchTerm) {
    setName(searchTerm);

    if (searchTerm !== "") {
      let temp = students;
      let filteredResult = temp.filter((winner) => {
        return Object.values(winner)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setFilteredStduents(filteredResult);
    } else {
      setFilteredStduents(students);
    }
  }

  // function filterByDob(sortType) {
  //   setSortType(sortType);
  //   const sortedData = filteredStudents.sort((a, b) => {
  //     const dateA = new Date(a.dob);
  //     const dateB = new Date(b.dob);

  //     if (sortType === "ascending") {
  //       return dateA - dateB; // Ascending order
  //     } else if (sortType === "descending") {
  //       return dateB - dateA; // Descending order
  //     } else {
  //       throw new Error(
  //         'Invalid sortType specified. Please use "ascending" or "descending".'
  //       );
  //     }
  //   });
  //   if (sortedData.length) {
  //     setFilteredStduents(sortedData);
  //   }
  //   return sortedData;
  // }

  function filterByLevel(sLevel, category) {
    if (!sLevel || !category) {
      alert("Please choose level and category");
      return;
    }

    const allStudents = prevStudents;
    if (allStudents.length) {
      const filteredStu = allStudents.filter((stu, index) => {
        return stu.lastCompletedLevel == sLevel && stu.category === category;
      });

      if (filteredStu.length) {
        console.log(filteredStu);
        setFilteredStduents(filteredStu);
        return;
      } else {
        alert(
          "No students found with the selcted filters! So showing all paginated results."
        );
        setFilteredStduents(prevStudents);
        return;
      }
    }
  }

  function handleResult(student) {
    navigate(`/student/progress/${student._id}`, { state: { student } });
  }

  function searchByCenter(center) {
    if (!center) {
      toast.warning(`Please select Valid filer option! `);

      return;
    } else {
      if (center === "all") {
        setFilteredStduents(prevStudents);
        return;
      }

      if (!students.length) {
        alert("There are no any students now!");
        return;
      }

      const filteredStudents = students.filter((student, index) => {
        return student.center === center ? student : null;
      });

      if (filteredStudents.length) {
        toast.success(`showing results matched with  ${center} `);

        setFilteredStduents(filteredStudents);
      } else if (filteredStudents.length === 0) {
        setFilteredStduents(0);
      } else {
        setFilteredStduents(prevStudents);
      }
    }
  }

  async function deleteStudentFromTest(student_Id) {
    try {
      const promptKey = window.confirm("Are you sure want to delete student ?");
      if (promptKey) {
        setBackdropState(true);
        const student = await axios.delete(
          `https://api.ascentabacus.com/admin/delete/student/${student_Id}`
        );
        if (student.data.statusCode === 200) {
          toast.success(student.data.message);
          setBackdropState(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return;
        }
      }
      setBackdropState(false);
    } catch (error) {
      setBackdropState(false);
      toast.error("Something went wrong! Try after sometime");
    }
  }

  function exportToExcel(tableId, sheetName, fileName) {
    const table = document.getElementById(tableId);

    // Clone the table and remove the last column
    const tableClone = table.cloneNode(true);
    const rows = tableClone.querySelectorAll("tr");
    rows.forEach((row) => {
      const lastCell = row.lastElementChild;
      if (lastCell) {
        row.removeChild(lastCell);
      }
    });

    const wb = XLSX.utils.table_to_book(tableClone, { sheet: sheetName });
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const downloadLink = document.createElement("a");
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.click();
  }


  

  async function getAllStudents(pageN) {
    setBackdropState(true);
    const allStudents = await axios.get(
      `https://api.ascentabacus.com/get-all-paginated-students?page=${pageN}&limit=${limit}`
    );

    if (allStudents.data.students) {
      const newestFirst = allStudents?.data?.students.reverse();
      setDocCount(allStudents?.data?.totalCount);
      setBackdropState(false);
      setStudents(newestFirst);
      setPrevStudents(newestFirst);
      setFilteredStduents(newestFirst);
    } else {
      // alert("something went wrong");
      setBackdropState(false);
    }
  }

  function handleNext() {
    setPage((p) => {
      if (page === pageCount) {
        return page;
      }
      return p + 1;
    });
    getAllStudents(page + 1);
  }

  function handlePrev() {
    if (page === 1) return;
    getAllStudents(page - 1);
    setPage((p) => {
      if (page === 1) return page;
      return p - 1;
    });
    getAllStudents(page - 1);
  }

  useEffect(() => {
    // setBackdropState(true);
    getAllStudents(page);
  }, []);

  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>
              &gt;
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                All Students
              </span>
            </h6>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backdropState}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="search-center">
              <div className="category">Search By</div>

              <div className="feature-section">
                <Box className="box">
                  <TextField
                    id="outlined-basic"
                    label="name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      searchByName(e.target.value);
                    }}
                  />
                </Box>
                <Box className="box">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      choose Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={searchLevel}
                      label="Age"
                      onChange={(e) => setSearchLevel(e.target.value)}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      choose Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={searchCategory}
                      label="Age"
                      onChange={(e) => setSearchCategory(e.target.value)}
                    >
                      <MenuItem value="jr">Junior</MenuItem>
                      <MenuItem value="sr">Senior</MenuItem>
                    </Select>
                  </FormControl>
                  <button
                    onClick={() => filterByLevel(searchLevel, searchCategory)}
                  >
                    Search
                  </button>
                </Box>

                <Box className="box">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Center
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={center}
                      label="Age"
                      onChange={(e) => setCenter(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>

                      <MenuItem value="Agartala, Tripura">
                        Agartala, Tripura
                      </MenuItem>
                      <MenuItem value="Ahmedabad">Ahmedabad</MenuItem>
                      <MenuItem value="Ajmer">Ajmer</MenuItem>
                      <MenuItem value="Bangalore">Bangalore</MenuItem>
                      <MenuItem value="Belgavi">Belgavi</MenuItem>
                      <MenuItem value="Bhilai">Bhilai</MenuItem>
                      <MenuItem value="Coimbatore">Coimbatore</MenuItem>
                      <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                      <MenuItem value="Indirapuram, Ghaziabad">
                        Indirapuram, Ghaziabad
                      </MenuItem>
                      <MenuItem value="Indore">Indore</MenuItem>
                      <MenuItem value="Jammu">Jammu</MenuItem>
                      <MenuItem value="Kolkata">Kolkata</MenuItem>
                      <MenuItem value="Ludhiana">Ludhiana</MenuItem>
                      <MenuItem value="Mumbai">Mumbai</MenuItem>
                      <MenuItem value="Nagpur">Nagpur</MenuItem>
                      <MenuItem value="Palam Vihar, Delhi NCR">
                        Palam Vihar, Delhi NCR
                      </MenuItem>
                      <MenuItem value="Pune">Pune</MenuItem>
                      <MenuItem value="Ranibazar, Tripura">
                        Ranibazar, Tripura
                      </MenuItem>
                      <MenuItem value="Sector 46, Gurgaon">
                        Sector 46, Gurgaon
                      </MenuItem>
                      <MenuItem value="Sector 56, Gurgaon">
                        Sector 56, Gurgaon
                      </MenuItem>
                      <MenuItem value="South City 1, Delhi NCR">
                        South City 1, Delhi NCR
                      </MenuItem>
                      <MenuItem value="South City 2, Delhi NCR">
                        South City 2, Delhi NCR
                      </MenuItem>
                      <MenuItem value="Sushant Lok 1, Delhi NCR">
                        Sushant Lok 1, Delhi NCR
                      </MenuItem>
                      <MenuItem value="Tilak Nagar, Dehradun">
                        Tilak Nagar, Dehradun
                      </MenuItem>
                      <MenuItem value="Vasant Vihar, Dehradun">
                        Vasant Vihar, Dehradun
                      </MenuItem>
                      <MenuItem value="Vellore">Vellore</MenuItem>
                      <MenuItem value="Warangal">Warangal</MenuItem>
                    </Select>
                  </FormControl>
                  <button onClick={() => searchByCenter(center)}>Search</button>
                </Box>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() =>
                      exportToExcel("all_students", "Sheet 1", "Students List")
                    }
                    style={{
                      border: "none",
                      padding: "20px 10px",
                      borderRadius: "5px",
                      background: "green",
                      color: "white",
                    }}
                  >
                    {" "}
                    <FileDownloadIcon /> Export Sheet
                  </button>
                </div>
              </div>
            </div>

            <div className="table_cont" id="all_students">
              <table>
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Dob</th>
                    <th>Category</th>
                    <th>Enrollment Status</th>
                    <th>Last Attempted Level</th>
                    <th>Registered On</th>
                    <th>Institution/Center</th>
                    <th>Payment Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents.length ? (
                    filteredStudents?.map((student, index) => {
                      return (
                        <tr key={index + 12}>
                          <td>
                            {page <= 1
                              ? index + 1
                              : (page - 1) * limit + (index + 1)}
                          </td>
                          <td>
                            {student?.firstname} {student?.lastname}
                          </td>
                          <td>{student?.email}</td>
                          <td>{student?.dob}</td>
                          <td>
                            {student?.category === "jr" ? "Junior" : "Senior"}
                          </td>
                          <td>
                            {student?.enrollmentStatus === "open" ? (
                              <span
                                style={{
                                  background: "blue",
                                  color: "white",
                                  padding: "6px 10px",
                                  borderRadius: "4px",
                                }}
                              >
                                Open
                              </span>
                            ) : (
                              student?.enrollmentStatus
                            )}
                          </td>

                          <td>
                            {student?.enrollmentStatus === "open"
                              ? "NA"
                              : student?.lastCompletedLevel}
                          </td>
                          <td>
                            {student?.createdAt
                              ? new Date(student?.createdAt).toDateString()
                              : "NA"}
                          </td>
                          <td>{student?.center ? student?.center : "NA"}</td>

                          <td>
                            {" "}
                            <span
                              style={{
                                background:
                                  student?.paymentStatus === "pending"
                                    ? "red"
                                    : "green  ",
                                color: "white",
                                display: "inline-block",
                                padding: "3px 9px",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {student?.paymentStatus !== "pending"
                                ? student?.paymentStatus?.split("|")[0]
                                : "pending"}
                            </span>
                          </td>
                          <td>
                            <button
                              onClick={() => handleResult(student)}
                              className="btn btn-primary"
                            >
                              View Progress
                            </button>
                            <button
                              style={{
                                background: "red",
                                color: "white",
                                border: "none",
                                marginLeft: "10px",
                              }}
                              className="btn btn-primary"
                              onClick={() => deleteStudentFromTest(student._id)}
                            >
                              <DeleteForeverIcon /> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "block",
                        marginLeft: "50px",
                        marginTop: "20px",
                        color: "red",
                      }}
                    >
                      No Data For This Search !
                    </span>
                  )}
                </tbody>
              </table>
              <nav>
                <button disabled={page === 1 || showAll} onClick={handlePrev}>
                  Prev
                </button>
                {/* <span>Current Page {page}-{page*10} : {page} / {docCount} </span> */}
                <span style={{ display: showAll ? "none" : "block" }}>
                  {page <= 1
                    ? `${page}-${page * 10}`
                    : `${(page - 1) * 10 + 1}-${page * 10}`}
                  /{docCount}
                </span>
                <button disabled={showAll} onClick={handleNext}>
                  Next
                </button>
                <button
                  onClick={() => {
                    getAllStudents("all");
                    setPage(1);
                    setShowAll(true);
                  }}
                >
                  Show All
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Students;

import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Navbar from "../navbar/Navbar";
import { useParams } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import "./studentprofile.scss";
import axios from "axios";
// modal import
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import './CustomDatePicker.css';

function MyVerticallyCenteredModal(props) {
  const { student } = props;
  const [backdropState, setBackdropState] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastName] = useState("");
  const [lastCompletedLevel, setLastCompletedLevel] = useState(null);
  const [lastCompletedLevelDate, setLastCompletedLevelDate] = useState("");
  const [enrollmentId, setEnrollmentId] = useState(null);
  const [category, setCategory] = useState("");
  const [grade, setGrade] = useState(null);
  const [postalAddress, setPostalAddress] = useState("");
  const [center, setCenter] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherContactNumber, setFatherContactNumber] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherContactNumber, setMotherContactNumber] = useState("");
  const [enrollmentYear, setEnrollmentYear] = useState("");

  const CustomInput = ({ value, onClick }) => (
    <button className="custom-datepicker-input" onClick={onClick}>
      {value || "Select Year"}
    </button>
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    // console.log(date.toDateString().split(" ")[3]);
    setEnrollmentYear(date.toDateString().split(" ")[3]);
  };

  async function setStudentDetails() {
    setEnrollmentId(student?.enrollmentId);
    setFirstname(student?.firstname);
    setLastName(student?.lastname);
    setDob(student?.dob);
    setLastCompletedLevel(student?.lastCompletedLevel);
    setLastCompletedLevelDate(student?.lastCompletedLevelDate);
    setCategory(student?.category);
    setEmail(student?.email);
    setPostalAddress(student?.postalAddress);
    setFatherName(student?.fatherName);
    setFatherContactNumber(student?.fatherContactNumber);
    setMotherName(student?.motherName);
    setMotherContactNumber(student?.motherContactNumber);
    setEnrollmentYear(student?.enrollmentYear);
    setCenter(student?.center);
    setGrade(student?.grade);
  }

  async function editStudentProfile() {
    try {
      setBackdropState(true);

      const res = await axios.post("https://api.ascentabacus.com/admin/edit/student", {
        enrollmentId,
        firstname,
        lastname,
        dob,
        lastCompletedLevel,
        lastCompletedLevelDate,
        category,
        email,
        postalAddress,
        fatherName,
        fatherContactNumber,
        motherName,
        motherContactNumber,
        enrollmentYear,
        center,
        grade,
        enrollmentStatus: student?.enrollmentStatus,
        studentId: student?._id,
      });
     
      if (res.data.statusCode === 200) {
        toast.success(res.data.message);
        setBackdropState(false);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return;
      }

      setBackdropState(false);
    } catch (error) {
    toast.error("Something went wrong!")
    setBackdropState(false);
    }
  }

  useEffect(() => {
    setStudentDetails();
  }, [props.student]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-details">
          <table>
            <thead>
              {student?.enrollmentStatus === "open" ? (
                ""
              ) : (
                <tr>
                  <th>Enrollment Id</th>
                  <td>
                    <Form.Control
                      value={enrollmentId}
                      onChange={(e) => setEnrollmentId(e.target.value)}
                      size="md"
                      type="text"
                    />
                  </td>
                </tr>
              )}

              <tr>
                <th>First Name</th>
                <td>
                  <Form.Control
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <Form.Control
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Dob</th>
                <td>
                  <DatePicker
                    className="datepicker"
                    value={dob}
                    selected={selectedDate}
                    onChange={(e) => {
                      setDob(new Date(e).toLocaleDateString());
                    }}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    placeholderText="Select Date"
                  />
                </td>
              </tr>

              {student?.enrollmentStatus === "open" ? (
                ""
              ) : (
                <tr>
                  <th>Last Completed Level</th>
                  <td>
                    <Form.Select
                      value={lastCompletedLevel}
                      aria-label="Default select example"
                      onChange={(e) => setLastCompletedLevel(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </Form.Select>
                  </td>
                </tr>
              )}

              {student?.enrollmentStatus === "open" ? (
                ""
              ) : (
                <tr>
                  <th>Center</th>
                  <td>
                    <Form.Select
                      value={center}
                      onChange={(e) => setCenter(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="Agartala, Tripura">
                        Agartala, Tripura
                      </option>
                      <option value="Ahmedabad">Ahmedabad</option>
                      <option value="Ajmer">Ajmer</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Belgavi">Belgavi</option>
                      <option value="Bhilai">Bhilai</option>
                      <option value="Coimbatore">Coimbatore</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Indirapuram, Ghaziabad">
                        Indirapuram, Ghaziabad
                      </option>
                      <option value="Indore">Indore</option>
                      <option value="Jammu">Jammu</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Ludhiana">Ludhiana</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Nagpur">Nagpur</option>
                      <option value="Palam Vihar, Delhi NCR">
                        Palam Vihar, Delhi NCR
                      </option>
                      <option value="Pune">Pune</option>
                      <option value="Ranibazar, Tripura">
                        Ranibazar, Tripura
                      </option>
                      <option value="Sector 46, Gurgaon">
                        Sector 46, Gurgaon
                      </option>
                      <option value="Sector 56, Gurgaon">
                        Sector 56, Gurgaon
                      </option>
                      <option value="South City 1, Delhi NCR">
                        South City 1, Delhi NCR
                      </option>
                      <option value="South City 2, Delhi NCR">
                        South City 2, Delhi NCR
                      </option>
                      <option value="Sushant Lok 1, Delhi NCR">
                        Sushant Lok 1, Delhi NCR
                      </option>
                      <option value="Tilak Nagar, Dehradun">
                        Tilak Nagar, Dehradun
                      </option>
                      <option value="Vasant Vihar, Dehradun">
                        Vasant Vihar, Dehradun
                      </option>
                      <option value="Vellore">Vellore</option>
                      <option value="Warangal">Warangal</option>
                    </Form.Select>
                  </td>
                </tr>
              )}

              {student?.enrollmentStatus === "past" ? (
                <tr>
                  <th>Last level completed on (mm/yyyy)</th>
                  <td>
                    <DatePicker
                      className="datepicker"
                      selected={selectedDate}
                      value={lastCompletedLevelDate}
                      onChange={(date) => {
                        if (date) {
                          const month = date.getMonth() + 1; // Adjust the month to be 1-based
                          const year = date.getFullYear();
                          const formattedDate = `${month}/${year}`;
                          setLastCompletedLevelDate(formattedDate);
                        }
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      isClearable
                      placeholderText="Select Month/Year"
                    />
                  </td>
                </tr>
              ) : (
                ""
              )}

              <tr>
                <th>Category</th>
                <td>
                  {/* <Form.Control value={category} size="md" type="text" /> */}
                  <Form.Select
                    value={category}
                    aria-label="Default select example"
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="sr">Sr</option>
                    <option value="jr">Jr</option>
                  </Form.Select>
                </td>
              </tr>
              {/* <tr>
                <th>Email</th>
                <td>
                  <Form.Control value={email} size="md" type="text" />
                </td>
              </tr> */}
              {student?.enrollmentStatus === "open" ? (
                <tr>
                  <th>Grade</th>
                  <td>
                    <Form.Select
                      value={grade}
                      aria-label="Default select example"
                      onChange={(e) => setGrade(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </Form.Select>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </thead>
          </table>

          <table>
            <thead>
              <tr>
                <th>Postal Address</th>
                <td>
                  <textarea
                    value={postalAddress}
                    onChange={(e) => setPostalAddress(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Father Name</th>
                <td>
                  <Form.Control
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Father Contact</th>
                <td>
                  <Form.Control
                    value={fatherContactNumber}
                    onChange={(e) => setFatherContactNumber(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Mother Name</th>
                <td>
                  <Form.Control
                    value={motherName}
                    onChange={(e) => setMotherName(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th>Mother Contact</th>
                <td>
                  <Form.Control
                    value={motherContactNumber}
                    onChange={(e) => setMotherContactNumber(e.target.value)}
                    size="md"
                    type="text"
                  />
                </td>
              </tr>

              {student?.enrollmentStatus === "open" ? (
                ""
              ) : (
                <tr>
                  <th>Enrollment Year</th>
                  <td>
                    {" "}
                    <DatePicker
                      className="datepicker"
                      selected={null}
                      value={enrollmentYear}
                      dateFormat="yyyy"
                      showYearPicker
                      yearDropdownItemNumber={10}
                      onChange={handleDateChange}
                      customInput={<CustomInput />}
                    />
                  </td>
                </tr>
              )}
            </thead>
          </table>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropState}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={editStudentProfile}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

const StudentProfile = () => {
  const [student, setStudent] = useState();
  const { studentId } = useParams();
  const [modalShow, setModalShow] = React.useState(false);

  async function getStudent() {
    const response = await axios(
      `https://api.ascentabacus.com/get-student/${studentId}`
    );

    setStudent(response.data.student);
  }

  useEffect(() => {
    getStudent();
  }, []);

  return (
    <div>
      <div className="home">
        <div className="homeContainer">
          <h6 id="sample">
            {" "}
            <span
              style={{
                color: "#A4A4A4",
                margin: "5px 0",
                display: "inline-block",
              }}
            >
              Student
            </span>{" "}
            &gt;{" "}
            <span
              style={{
                color: "black",
                margin: "5px 0",
                display: "inline-block",
                fontSize: "1rem",
                fontWeight: "bolder",
              }}
            >
              Profile
            </span>{" "}
          </h6>
              
          <div
            className="edit-btn"
            style={{ display: "flex", justifyContent: "flex-end",margin:"10px 0" }}
          >
            <Button variant="primary" onClick={() => setModalShow(true)}>
              Edit Profile
            </Button>
          </div>
          <div className="student_profile">
            <div className="avatar">
              <Avatar
                id="avt"
                name={`${student?.firstname}  ${student?.lastname}`}
                size="150px"
                round
              />
              <span style={{ textTransform: "capitalize" }}>
                {" "}
                {student?.firstname} {student?.lastname}
              </span>
            </div>

            <div className="student-details">
              <table>
                <thead>
                  {student?.enrollmentStatus === "open" ? (
                    " "
                  ) : (
                    <tr>
                      <th>Enrollment Id</th>
                      <td>{student?.enrollmentId}</td>
                    </tr>
                  )}

                  <tr>
                    <th>Student</th>
                    <td>
                      {student?.firstname} {student?.lastname}
                    </td>
                  </tr>
                  <tr>
                    <th>Dob</th>
                    <td>{student?.dob}</td>
                  </tr>

                  {student?.enrollmentStatus === "open" ? (
                    " "
                  ) : (
                    <tr>
                      <th>Last Completed Level</th>
                      <td>{student?.lastCompletedLevel}</td>
                    </tr>
                  )}

                  {student?.lastCompletedLevelDate ? (
                    <tr>
                      <th>Last Level Completed On </th>
                      <td>{student?.lastCompletedLevelDate}</td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <th>Category</th>
                    <td>{student?.category}</td>
                  </tr>

                  {student?.enrollmentStatus === "open" ? (
                    <tr>
                      <th>Grade</th>
                      <td>{student?.grade}</td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {student?.center ? (
                    <tr>
                      <th>Center</th>
                      <td>{student?.center}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <th>Email</th>
                    <td>{student?.email}</td>
                  </tr>
                  <tr>
                    <th>Postal Address</th>
                    <td>{student?.postalAddress}</td>
                  </tr>
                </thead>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>Father Name</th>
                    <td>{student?.fatherName}</td>
                  </tr>
                  <tr>
                    <th>Father Contact</th>
                    <td>{student?.fatherContactNumber}</td>
                  </tr>
                  <tr>
                    <th>Mother Name</th>
                    <td>{student?.motherName}</td>
                  </tr>
                  <tr>
                    <th>Mother Contact</th>
                    <td>{student?.motherContactNumber}</td>
                  </tr>

                  {student?.enrollmentStatus === "open" ? (
                    " "
                  ) : (
                    <tr>
                      <th>Enrollment Year</th>
                      <td>{student?.enrollmentYear}</td>
                    </tr>
                  )}

                  <tr>
                    <th>Enrollment Status</th>
                    <td>{student?.enrollmentStatus}</td>
                  </tr>
                </thead>
              </table>

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                student={student}
              />
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;

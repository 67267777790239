import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children }) => {


  let isAuthenticated = JSON.parse(localStorage.getItem("auth"))
    ? true
    : false;
    
  useEffect(() => {}, []);

  if (!isAuthenticated) {
    return <Navigate to={"/login"} />;
  } else {
    return children ? children : <Outlet />;
  }
};

export default ProtectedRoute;

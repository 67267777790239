import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./issuecard.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
 
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const IssueCards = (props) => {
  const { studentId: id } = useParams();

  const [student, setStudent] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [issueId, setIssueId] = useState("");
  const [backdropState, setBackdropState] = useState(false);
  const [previewImageUrl,setPreviewImageUrl] = useState("");

  const handleClose = () => setOpen(false);
  const handleCloseImagePreview = () => setOpenImage(false);
  const handleOpenImagePreview = (imageUrl) => {setOpenImage(true);
    setPreviewImageUrl(imageUrl)
  };

  const [remark, setRemark] = useState("");
  const [issueStatus, setIssueStatus] = useState("");

  async function getCurrentStudent() {
    try {
      setBackdropState(true);
      const stud = await axios(`https://api.ascentabacus.com/get-student/${id}`);
      if (stud.data.student) {
        setStudent(stud.data.student);
        setBackdropState(false);
      }
    } catch (error) {
      setBackdropState(false);

      toast.error("Something went wrong!");
    }
  }

  const handleOpen = (issueId) => {
    setOpen(true);
    setIssueId(issueId);
  };

  async function handleIssueStatus() {
    try {
      if (!remark || !issueStatus) {
        toast.warning("Please select Issue Status and Remark for it!");
        return;
      } else {
        const issueRes = await axios.post(`https://api.ascentabacus.com/update/issue/${issueId}`, {
          remark,
          issueStatus,
        });
        if (issueRes.data.statusCode === 200) {
          handleClose();
          toast.success(issueRes.data.message);
          return;
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      return;
    }
  }
  useEffect(() => {
    getCurrentStudent();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>
              &nbsp; &gt; &nbsp;
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                All Issues
              </span>
            </h6>

            <div className="all_issue">
              <table>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>Issue Title</th>
                    <th>Issue Description</th>
                    <th>Raised On</th>
                    <th>Screenshot</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {student && student?.raisedIssues?.length
                    ? student?.raisedIssues.map((issue, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{student.firstname}</td>
                            <td>{issue.issueTitle}</td>
                            <td>{issue.issueDescription}</td>
                            <td>
                              {" "}
                              {new Date(issue.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <img
                                onClick={()=>handleOpenImagePreview(issue.issueImageLink)}
                                src={issue.issueImageLink}
                                style={{ height: "40px", width: "40px" }}
                                alt=""
                              />
                            </td>
                            <td>{issue.status}</td>
                            <td>
                              <span
                                onClick={() => handleOpen(issue._id)}
                                style={{
                                  display: "inline-block",
                                  cursor: "pointer",
                                  background: "blue",
                                  padding: "6px 4px",
                                  color: "white",
                                  borderRadius: "4px",
                                }}
                              >
                                update status
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    : "No record"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Issue Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="status"
                    onChange={(e) => setIssueStatus(e.target.value)}
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"resolved"}>Resolved</MenuItem>
                    <MenuItem value={"in-progress"}>In Progress</MenuItem>
                  </Select>
                </FormControl>
                <div className="inp">
                  <label htmlFor="rmark">Remark</label>
                  <input
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                    type="text"
                    id="rmark"
                  />
                </div>
                <div>
                  <button
                    onClick={() => handleIssueStatus()}
                    style={{
                      marginTop: "10px",
                      padding: "6px 10px",
                      background: "#FA5F43",
                      border: "none",
                      color: "white",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={openImage}
          onClose={handleCloseImagePreview}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
       <Box sx={style}>
            <img
              src={previewImageUrl}
              alt=""
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default IssueCards;

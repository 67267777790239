import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { toast } from "react-toastify";
import "./wrong_question.scss";

const WrongQuestions = () => {
  const [backdropState, setBackdropState] = useState(false);
  const [wrongQuestions, setWrongQuestions] = useState([]);
  async function getWrongQuestions() {
    setBackdropState(true);
    try {
      const res = await axios(
        "https://api.ascentabacus.com/get/incorrect-questions"
      );
      if (res.data.statusCode === 200) {
        setWrongQuestions(res.data.wrong_questions);
        setBackdropState(false);

        return;
      }
      setBackdropState(false);
    } catch (error) {
      toast.error("something went wrong!");
      setBackdropState(false);

      return;
    }
  }
  async function deleteMcqIssueDoc(wrongMcqDocId) {
    setBackdropState(true);

    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delet? Delete If questions has fixed."
      );
      if (shouldDelete) {
        const res = await axios.delete(
          `https://api.ascentabacus.com/delete/incorrect-question-doc/${wrongMcqDocId}`
        );
        
        if (res.data.statusCode === 200) {
          toast.success("This Issue Doc has deleted succesfully!");
          setBackdropState(false);
        
          return;
        }
      }
      setBackdropState(false);
    } catch (error) {
      setBackdropState(false);

      toast.error("Something went wrong!");
      return;
    }
  }
  useEffect(() => {
    getWrongQuestions();
  }, [WrongQuestions]);

  console.log("wrong", WrongQuestions);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>
              &nbsp; &gt; &nbsp;
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                Wrong Questions
              </span>
            </h6>

            <div className="all_wrong_questions">
              <table>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Student Name</th>
                    <th>Student Email</th>
                    <th>Paper Level</th>
                    <th>Paper Id</th>
                    <th>Paper Category</th>
                    <th>Questions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {wrongQuestions.length
                    ? wrongQuestions.map((wrgQuestion, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {wrgQuestion?.student?.firstname}{" "}
                              {wrgQuestion?.student?.lastname}
                            </td>
                            <td>{wrgQuestion?.student?.email}</td>
                            <td>{wrgQuestion?.paperId?.level}</td>
                            <td>{wrgQuestion?.paperId?._id}</td>
                            <td>{wrgQuestion?.paperId?.category}</td>
                            <td>
                              {wrgQuestion?.wrongQuestionList.map(
                                (questionIndex, index) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>
                                        Question No - {questionIndex} <br></br>
                                        <span style={{ color: "red" }}>
                                          Question : &nbsp;
                                          {
                                            wrgQuestion?.paperId?.questions[
                                              questionIndex - 1
                                            ]?.question
                                          }
                                        </span>
                                      </span>

                                      <span>
                                        Option A : &nbsp;
                                        {
                                          wrgQuestion?.paperId?.questions[
                                            questionIndex - 1
                                          ]?.optionA
                                        }
                                      </span>
                                      <span>
                                        Option B : &nbsp;
                                        {
                                          wrgQuestion?.paperId?.questions[
                                            questionIndex - 1
                                          ]?.optionB
                                        }
                                      </span>
                                      <span>
                                        Option C : &nbsp;
                                        {
                                          wrgQuestion?.paperId?.questions[
                                            questionIndex - 1
                                          ]?.optionC
                                        }
                                      </span>
                                      <span>
                                        Option D : &nbsp;
                                        {
                                          wrgQuestion?.paperId?.questions[
                                            questionIndex - 1
                                          ]?.optionD
                                        }
                                      </span>
                                      <span>
                                        Answer Provided : &nbsp;
                                        {
                                          wrgQuestion?.paperId?.questions[
                                            questionIndex - 1
                                          ]?.answer
                                        }
                                      </span>
                                      <hr />
                                    </div>
                                  );
                                }
                              )}
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  deleteMcqIssueDoc(wrgQuestion._id)
                                }
                              >
                                Delete{" "}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : "No Such Issue Reported yet!"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WrongQuestions;

import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import "./studentprogress.scss";
import StudentProfile from "../Student profile/StudentProfile";

const StudentProgess = () => {
  const locationData = useLocation();
  const [student, setStudent] = useState();

  useEffect(() => {

    setStudent(locationData.state.student);
    // console.log(student)
  }, [student]);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Asia/Kolkata",
  };

  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div
            className="content"
            style={{ padding: "20px", background: "#F5F5F5" }}
          >
            <StudentProfile />

            <h6 style={{ margin: "15px" }} id="sample">
              {" "}
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Result{" "}
              </span>{" "}
              &gt;{" "}
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                {student?.firstname} {student?.lastname}
              </span>{" "}
            </h6>
            <div className="cont">
              <div className="table-container">
                {
                  student?.quizResults?.length === 0 ?  <span style={{color:"red"}}>No Data or Student has not participated in any test!</span> :  <table>
                  <thead>
                    <tr>
                      <th>Paper No</th>
                      {/* <th>Student Name</th> */}
                      {/* <th>Student Level</th> */}
                      <th>Category</th>
                      <th>Paper Level</th>
                      <th>Paper Type</th>
                      <th>Attempt Date</th>
                      <th>Time Taken</th>
                      <th>Questions Attempted</th>
                      <th>Total Questions</th>
                      <th>Correct Answers</th>
                      <th>Incorrect Answers</th>
                      <th>Total Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {student?.quizResults?.map((paper, index) => {
                      return (
                        <tr key={index + 2}>
                          <td>{index + 1}</td>
                          {/* <td>
                            {student.firstname} {student.lastname}
                          </td> */}
                          {/* <td>{student.studentLevel}</td> */}
                          <td>{student.category === 'jr' ? "Junior" : "Senior"}</td>
                          <td>{paper?.questions?.questionId?.level}</td>
                          <td>{paper?.questions?.questionId?.paperType}</td>
                          <td>
                            {new Date(paper.date).toLocaleDateString(
                              "en-US",
                              options
                            )}{" "}
                          </td>
                          <td>
                          {paper?.timeTaken}
                          </td>
                          <td>
                          {paper?.numQuestionsAttempted}
                          </td>
                          <td>
                            {paper?.questions?.questionId?.questions.length}
                          </td>
                         

                          <td style={{display:"flex",alignItems:"center",justifyContent:"center"}}>  <span style={{display:"flex",background:"green",color:"white",borderRadius:"50%",width:"40px",height:"40px",alignItems:"center",justifyContent:"center"}}>{paper.score}</span> </td>

                          <td>{paper.numWrongAnswers}</td>
                          <td>
                            {paper.score}/
                            {paper?.questions?.questionId?.questions.length}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                }



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProgess;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import "./login.scss";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";



const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();

  const navigate = useNavigate();

  async function handleLogin() {
    try {
      if (!email || !password) {
        toast.error(`All fields are required!`);

        return;
      }
      const response = await axios.post("https://api.ascentabacus.com/admin/login", { email, password });
 
    //   dispatch({type: LOGIN_SUCCESS ,payload : response.data })
      if (response.data.admin) {
        toast.success("Logged in Succesfully!");
        window.localStorage.setItem("auth",JSON.stringify(response.data.admin))

        navigate("/");
        
        return ;
      }else{
        toast.warning("Email or password is Invalid!");
        return ;
      }
    } catch (error) {
      toast.error(`Some Error Occured ${error.message}`);
    }
  }

  return (
    <div className="">
      <div className="main_wrapper">
        <div className="l_form_wrapper">
          <div className="l_logo">
            <LockOpenIcon className="user-icon" />
            <h2>Welcome Back, Admin</h2>
          </div>
          <div className="l_input_wrappers">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="l_box">
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                placeholder="Email Address*"
                className="l_input"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div className="l_box">
              <TextField
              style={{width:"100%"}}
                id="outlined-basic"
                label=""
                variant="outlined"
                placeholder="Password*"
                className="l_input"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div
            className="btn"
            style={{ display:"flex",justifyContent:"space-between", margin: "20px 0" }}
          >
            <button
              style={{
                color: "#fff",
                backgroundImage: "linear-gradient(90deg, rgb(195, 0, 42) 0%, rgb(0, 0, 0) 0%, rgba(2, 17, 19, 0.82) 100%)",
                padding:"10px",
                borderRadius:'7px'
              
              }}
            
              onClick={handleLogin}
            >
              Login
            </button>
          {/* <div style={{textAlign:"center",textDecoration:"none"}}>
              <Link  style={{textDecoration:"none"}} to="/register"> New ? <br/> Register</Link>
          </div> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Login;

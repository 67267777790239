import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import "./finalresult.scss";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';




const FinalResult = () => {
  const [students, setStudents] = useState([]);
  const [paperLevel, setPaperLevel] = useState("");
  const [category, setCategory] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [backdropState, setBackdropState] = useState(false);
  const tableData = document.querySelector("table");

  async function getStudentResult() {
    const studentResponse = await axios.get("https://api.ascentabacus.com/finalpaper/result");

    setBackdropState(false)
    console.log(studentResponse.data.students)
    setStudents(studentResponse.data.students);
    setFilteredData(studentResponse.data.students);
  }

  function exportToExcel(tableData, fileName) {
   
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Convert the table data into a worksheet
    const ws = XLSX.utils.table_to_sheet(tableData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the workbook as an Excel file
    const data = XLSX.writeFile(wb, `${fileName}.xlsx`);
    
    

  }


  useEffect(() => {
    setBackdropState(true)
    getStudentResult();
  }, []);

  function handleFilters(selectedLevel, selectedCategory) {
    if (!selectedLevel || !selectedCategory) {
      toast.error("Please choose both Level And Category!");
      return;
    }

   
    if (selectedCategory === "all" || selectedCategory === "all") {
      setFilteredData(students);
      return;
    }

    const newfilteredData = students.filter((result) => {
      console.log("level", result.level);
      console.log("category", result.category);

      return result.level === selectedLevel &&
        result.category === selectedCategory
        ? result
        : null;
    });

    

    setFilteredData(newfilteredData);
  }

 


  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">


            
              
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropState}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            


            <h6 id="sample" style={{ marginBottom: "20px" }}>
              {" "}
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>{" "}
              &gt;{" "}
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                Final Result
              </span>{" "}
            </h6>

            <div className="search-center">
              <div className="category">Search By</div>

              <div className="feature-section">
                <Box className="box">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Paper level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={paperLevel}
                      label="Student level"
                      onChange={(e) => setPaperLevel(e.target.value)}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={"all"}>All</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box className="box">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      label="Category"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem value={"jr"}>Junior</MenuItem>
                      <MenuItem value={"sr"}>Senior</MenuItem>
                      <MenuItem value={"all"}>All</MenuItem>
                    </Select>
                  </FormControl>
                  <button
                    onClick={() => handleFilters(paperLevel, category)}
                    style={{
                      backgroundColor: "#5327b9",
                    }}
                  >
                    Search
                  </button>
                </Box>
                <Box className="box" style={{display:"flex",justifyContent:'end'}}>
                  <button
                
                    onClick={() => exportToExcel(tableData,`student result`)}
                    style={{
                      backgroundColor: "green",
                      pointerEvents: "painted"
                    }}
                  >
                    Export Result
                  </button>
                </Box>
              </div>
            </div>

            <div className="table_cont">
              {students?.length === 0 ? (
                <span style={{ color: "red" }}>
                  Seems like no student has attempted final test paper
                </span>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Paper Level</th>
                      <th>Category</th>
                      {/* <th>Student Level</th> */}
                      <th>Institution/Center</th>
                      {/* <th>Phone</th> */}
                      <th>Paper Type</th>
                      <th>Attempt Time</th>
                      <th>Total Questions</th>
                      <th>Time Taken</th>
                      <th>Correct Ans</th>
                      <th>InCorrect Ans</th>
                      <th>Questions Attempted</th>
                      <th>Total Score</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.length ? (
                      filteredData?.map((student, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{student?.name}</td>
                            <td>{student?.level}</td>
                            <td>
                              {student?.category === "sr" ? "Senior" : "Junior"}
                            </td>
                            {/* <td>{student.studentLevel}</td> */}
                            <td>{student?.center}</td>
                            {/* <td>{student?.phone}</td> */}
                            <td>{student?.paperType}</td>
                            <td>{new Date(student?.date).toLocaleString() }</td>
                            <td>{student?.questions?.length}</td>
                            <td>{student?.timeTaken}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  display: "flex",
                                  background: "green",
                                  color: "white",
                                  borderRadius: "50%",
                                  width: "40px",
                                  height: "40px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {student?.score}
                              </span>{" "}
                            </td>
                            <td>{student?.numWrongAnswers}</td>

                            <td>{student?.numQuestionsAttempted}</td>
                            <td>
                              {student?.score}/{student?.questions?.length}
                            </td>
                            {/* <td>
                              <button>check paper</button>
  
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <span style={{ color: "red" }}>No Student found !</span>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalResult;

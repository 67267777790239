import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import * as XLSX from "xlsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { upload } from "@testing-library/user-event/dist/upload";
import axios from "axios";
import { toast } from "react-toastify";

const Home = () => {
  const [level, setLevel] = useState(1);
  const [category, setCategory] = useState("");
  const [questions, setQuestions] = useState([]);
  const [paperType, setPaperType] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const filteredData = sheetData.filter((row) =>
        row.some((cell) => !!cell)
      ); // filter out blank rows

      const keys = filteredData.shift(); // remove the first element and store it as keys

      // const result = filteredData.map((item) => {
      //   const obj = {};
      //   keys.forEach((key, index) => (obj[key] = item[index]));
      //   return obj;
      // });

      const result = filteredData.map((item) => {
        const obj = {};
        keys.forEach((key, index) => {
          const value = item[index];
          obj[key] = typeof value === 'number' ? value.toFixed(2) : value;
        });
        return obj;
      });
      
      setQuestions(result);
    };
    reader.readAsBinaryString(file);
  };

  async function uploadData() {
    try {
      if (!level || !questions.length || !category) {
        toast.warning("please select required fields and choose file in proper format");
        return;
      }
      const response = await axios.post("https://api.ascentabacus.com/upload-questions", {
        level,
        questions,
        category,
        paperType,
      });

      if (!response.data.status) {
        toast.success("Questions uploaded succesfully");
        setCategory("");
        setLevel("");
        setQuestions("");
      } else {
        toast.error(
          "File has some error, please check file and upload proper data"
        );
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="content">
          <div className="main_content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              {" "}
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>{" "}
              &gt;{" "}
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                Upload Questions
              </span>{" "}
            </h6>

            <div className="options">


              <Box sx={{ minWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Level</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ display: "flex", alignItems: "center" }}
                    value={level}
                    onChange={(event) => {
                      setLevel(event.target.value);
                    }}
                  >
                    <MenuItem value={1}>level 1</MenuItem>
                    <MenuItem value={2}>level 2</MenuItem>
                    <MenuItem value={3}>level 3</MenuItem>
                    <MenuItem value={4}>level 4</MenuItem>
                    <MenuItem value={5}>level 5</MenuItem>
                    <MenuItem value={6}>level 6</MenuItem>
                    <MenuItem value={7}>level 7</MenuItem>
                    <MenuItem value={8}>level 8</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ display: "flex", alignItems: "center" }}
                    value={category}
                    onChange={(event) => {
                      setCategory(event.target.value);
                    }}
                  >
                    <MenuItem value={"jr"}>Junior</MenuItem>
                    <MenuItem value={"sr"}>Senior</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* question paper type */}
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Paper Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ display: "flex", alignItems: "center" }}
                    value={paperType}
                    onChange={(event) => {
                      setPaperType(event.target.value);
                    }}
                  >
                    <MenuItem value={"sample"}>Sample paper</MenuItem>
                    <MenuItem value={"final"}>Final Paper</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Form.Control
                style={{ width: "20%" }}
                type="file"
                onChange={handleFileUpload}
              />

              <button
                style={{ margin: "12px 0" }}
                onClick={uploadData}
                type="button"
                class="btn btn-primary"
              >
                Upload
              </button>
            </div>

            <div className="upload-rules">
              <h5>Steps To Upload Question Paper Easily</h5>
              <ul>
                <li>Admin must choose the level of the question paper from the dropdown menu shown above.</li>
                <li>
                  Admin must choose the category of the question paper - junior
                  or senior.
                </li>
                <li>
                  Admin must select whether the paper being uploaded is a sample
                  or final paper.
                </li>
                <li>
                  Admin must upload a valid Excel sheet containing questions in
                  the correct format.
                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import "./payment.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import TablePagination from "@mui/material/TablePagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";

const Payments = () => {
  const [students, setStudents] = useState([]);
  const [checked, setChecked] = useState(false);
  const [origStudents, setOrigStudents] = useState([]);
  const [center, setCenter] = useState("");
  const [lastQualifiedLevel, setLastQualifiedLevel] = useState("");
  const [name, setName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [backdropState, setBackdropState] = useState(false);

  const [docCount, setDocCount] = useState();
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(10);
  const [showAll, setShowAll] = useState(false);
  const [page, setPage] = React.useState(1);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);

  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  function handleNext() {
    setPage((p) => {
      if (page === pageCount) {
        return page;
      }
      return p + 1;
    });
    getAllStudents(page + 1);
  }

  function handlePrev() {
    if (page === 1) return;
    getAllStudents(page - 1);
    setPage((p) => {
      if (page === 1) return page;
      return p - 1;
    });
    getAllStudents(page - 1);
  }
  async function getAllStudents(pageN) {
    setBackdropState(true);

    try {
      const allStudents = await axios(
        `https://api.ascentabacus.com/get-all-paginated-students?page=${pageN}&limit=${limit}`
      );
      if (allStudents?.data?.students) {
        const newestFirst = allStudents.data.students.reverse();
        
        setStudents(newestFirst);
        setOrigStudents(newestFirst);
        // toast.success("Students fetched succesfully");
        setBackdropState(false);

        return;
      }
    } catch (error) {
      setBackdropState(false);
    }
  }

  function searchStudent(searchTerm, type) {
    if (searchTerm === "all") {
      setStudents(origStudents);
      return;
    }
    if (searchTerm === "open") {
      const filteredStudents = origStudents.filter((student, index) => {
        return student?.enrollmentStatus === "open";
      });

      if (filteredStudents.length > 0) {
        toast.success(`Showing all the records matching ${searchTerm}`);
        setStudents(filteredStudents);
        return;
      } else {
        toast.warning(
          `Showing All result since no record found for this querry ${searchTerm}`
        );
        setStudents(origStudents);
        return;
      }
    }

    if (type === "center") {
      const filteredStudents = origStudents.filter((student, index) => {
        return student?.center === searchTerm;
      });
      if (filteredStudents.length > 0) {
        toast.success(`Showing all the records matching ${searchTerm}`);
        setStudents(filteredStudents);
        return;
      } else {
        toast.warning(
          `Showing All result since no record found for this querry ${searchTerm}`
        );
        setStudents(origStudents);
        return;
      }
    }
  }

  async function handlePaymentStatus(isChecked, student_Id) {
    setBackdropState(true);
    try {
      const res = await axios.post(
        "https://api.ascentabacus.com/admin/manage-payments",
        {
          isChecked,
          student_Id,
        }
      );

      if (res.data.status === 200) {
        toast.success("Payment Status Updated Succesfully!");
        window.location.reload();
        setBackdropState(false);
        return;
      }
    } catch (error) {
      setBackdropState(false);
      toast.error("something went wrong!");
    }
  }

  function searchByName(searchTerm) {
    setName(searchTerm);

    if (searchTerm !== "") {
      let temp = students;
      let filteredResult = origStudents.filter((student) => {
        return Object.values(student)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setStudents(filteredResult);
    } else {
      setStudents(origStudents);
    }
  }

  function exportToExcel(tableId, sheetName, fileName) {
    const table = document.getElementById(tableId);

    // Clone the table and remove the last column
    const tableClone = table.cloneNode(true);
    const rows = tableClone.querySelectorAll("tr");
    rows.forEach((row) => {
      const lastCell = row.lastElementChild;
      if (lastCell) {
        row.removeChild(lastCell);
      }
    });

    const wb = XLSX.utils.table_to_book(tableClone, { sheet: sheetName });
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const downloadLink = document.createElement("a");
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.click();
  }

  useEffect(() => {
    getAllStudents();
  }, []);

  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="content">
            <h6 id="sample" style={{ marginBottom: "20px" }}>
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>
              &nbsp; &gt; &nbsp;
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                Manage Payments
              </span>
            </h6>

            <div className="st-search-center">
              <div className="category">Search By</div>

              <div className="st-feature-section">
                <div className="left-st-search">
                  <Box className="box">
                    <TextField
                      label="name"
                      variant="outlined"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        searchByName(e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="box">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Center
                      </InputLabel>
                      <Select
                        className="center-select"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={center}
                        name="center"
                        onChange={(e) => {
                          setCenter(e.target.value);
                          searchStudent(e.target.value, e.target.name);
                        }}
                        label="Age"
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="open">Open</MenuItem>

                        <MenuItem value="Agartala, Tripura">
                          Agartala, Tripura
                        </MenuItem>
                        <MenuItem value="Ahmedabad">Ahmedabad</MenuItem>
                        <MenuItem value="Ajmer">Ajmer</MenuItem>
                        <MenuItem value="Bangalore">Bangalore</MenuItem>
                        <MenuItem value="Belgavi">Belgavi</MenuItem>
                        <MenuItem value="Bhilai">Bhilai</MenuItem>
                        <MenuItem value="Coimbatore">Coimbatore</MenuItem>
                        <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                        <MenuItem value="Indirapuram, Ghaziabad">
                          Indirapuram, Ghaziabad
                        </MenuItem>
                        <MenuItem value="Indore">Indore</MenuItem>
                        <MenuItem value="Jammu">Jammu</MenuItem>
                        <MenuItem value="Kolkata">Kolkata</MenuItem>
                        <MenuItem value="Ludhiana">Ludhiana</MenuItem>
                        <MenuItem value="Mumbai">Mumbai</MenuItem>
                        <MenuItem value="Nagpur">Nagpur</MenuItem>
                        <MenuItem value="Palam Vihar, Delhi NCR">
                          Palam Vihar, Delhi NCR
                        </MenuItem>
                        <MenuItem value="Pune">Pune</MenuItem>
                        <MenuItem value="Ranibazar, Tripura">
                          Ranibazar, Tripura
                        </MenuItem>
                        <MenuItem value="Sector 46, Gurgaon">
                          Sector 46, Gurgaon
                        </MenuItem>
                        <MenuItem value="Sector 56, Gurgaon">
                          Sector 56, Gurgaon
                        </MenuItem>
                        <MenuItem value="South City 1, Delhi NCR">
                          South City 1, Delhi NCR
                        </MenuItem>
                        <MenuItem value="South City 2, Delhi NCR">
                          South City 2, Delhi NCR
                        </MenuItem>
                        <MenuItem value="Sushant Lok 1, Delhi NCR">
                          Sushant Lok 1, Delhi NCR
                        </MenuItem>
                        <MenuItem value="Tilak Nagar, Dehradun">
                          Tilak Nagar, Dehradun
                        </MenuItem>
                        <MenuItem value="Vasant Vihar, Dehradun">
                          Vasant Vihar, Dehradun
                        </MenuItem>
                        <MenuItem value="Vellore">Vellore</MenuItem>
                        <MenuItem value="Warangal">Warangal</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <button onClick={() => searchByCenter(center)}>Search</button> */}
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() =>
                      exportToExcel("all_students", "Sheet 1", "Students List")
                    }
                    style={{
                      color: "white",
                      border: "none",
                      padding: "20px 10px",
                      borderRadius: "5px",
                      background: "green",
                    }}
                  >
                    {" "}
                    <FileDownloadIcon /> Export Sheet
                  </button>
                </div>
              </div>
            </div>

            <div className="students_lists">
              <table id="all_students">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Student Name</th>
                    <th>Enrollement Id</th>
                    <th>Email</th>
                    <th>Category</th>
                    <th>Center</th>
                    <th>Registered On</th>
                    <th>Payment Status</th>
                    <th>Manage Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {students
                    ? students.map((student, index) => {
                        return (
                          <tr>
                            <td>
                              {page <= 1
                                ? index + 1
                                : (page - 1) * limit + (index + 1)}
                            </td>

                            <td>
                              {student?.firstname} {student?.lastname}
                            </td>
                            <td>
                              {student?.enrollmentStatus === "open" ? (
                                <span
                                  style={{
                                    background: "blue",
                                    color: "white",
                                    padding: "6px 10px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Open
                                </span>
                              ) : (
                                student?.enrollmentId
                              )}
                            </td>
                            <td>{student?.email}</td>
                            <td>{student?.category}</td>
                            <td>
                              {student?.enrollmentStatus === "open"
                                ? "NA"
                                : student?.center}
                            </td>
                            <td>
                              {student?.createdAt
                                ? new Date(student?.createdAt).toDateString()
                                : "NA"}
                            </td>
                            <td>
                              <span
                                style={{
                                  background:
                                    student?.paymentStatus === "pending"
                                      ? "red"
                                      : "green  ",
                                  color: "white",
                                  display: "inline-block",
                                  padding: "3px 9px",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {student?.paymentStatus !== "pending"
                                  ? student?.paymentStatus?.split("|")[0]
                                  : "pending"}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <Switch
                                // style={{color:  student?.paymentStatus === "pending" ? "red" : "green  "}}
                                checked={
                                  student?.paymentStatus === "pending"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  handlePaymentStatus(
                                    e.target.checked,
                                    student._id
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                    : "No Students Found :("}
                </tbody>
              </table>
              {/* <nav>
                {students.length > 5 ? (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={students.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : (
                  ""
                )}
              </nav> */}
              <nav style={{display:'flex',alignItems:'center',gap:"15px"}}>
                <button disabled={page === 1 || showAll} onClick={handlePrev}>
                  Prev
                </button>
                {/* <span>Current Page {page}-{page*10} : {page} / {docCount} </span> */}
                <span style={{ display: showAll ? "none" : "block" }}>
                  {page <= 1
                    ? `${page}-${page * 10}`
                    : `${(page - 1) * 10 + 1}-${page * 10}`}
                  /{docCount}
                </span>
                <button disabled={showAll} onClick={handleNext}>
                  Next
                </button>
                <button
                  onClick={() => {
                    getAllStudents("all");
                    setPage(1);
                    setShowAll(true);
                  }}
                >
                  Show All
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Payments;

import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./questionpapers.scss";
import axios from "axios";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const QuestionPapers = () => {
  const [samplePapers, setSamplePapers] = useState([]);
  const [paperType, setPaperType] = useState("");
  const [paperLevel, setPaperLevel] = useState("");
  const [paperCategory, setPaperCategory] = useState("");
  const [filteredPapers, setFilteredPapers] = useState();
  const [backdropState, setBackdropState] = useState(false);

  const navigate = useNavigate();

  async function getPapers() {
    const reponse = await axios.get("https://api.ascentabacus.com/get-sample-papers");
    if (reponse.data.question) {
      setBackdropState(false);
      setSamplePapers(reponse.data.question);
      setFilteredPapers(reponse.data.question);
    }

    setBackdropState(false);
  }

  useEffect(() => {
    setBackdropState(true);
    getPapers();
  }, []);

  function jumpToQuestions(questions, paperid) {
    navigate(`/paper/${paperid}`, { state: { questions } });
  }

  async function deletePaper(_id) {
    try {
      const response = await axios.delete(
        `https://api.ascentabacus.com/delete-paper/${_id}`,
        { _id }
      );
      if (response.data.paper) {
        toast.success("Paper Deleted Succesfully!");
        window.location.reload();
        return;
      }
    } catch (error) {
      toast.error("Something went wrong !");
    }
  }

  function search(searchTerm, searchType) {
    if (!searchTerm || !searchType) {
      toast.success(`Please choose a valid filter!`);

      return;
    } else if (searchTerm === "all") {
      toast.success("Showing All results!");
      setFilteredPapers(samplePapers);
      return;
    }

    const filteredResult = samplePapers?.filter((paper, index) => {
      return paper[searchType] === searchTerm ? paper : null;
    });

    if (filteredResult.length) {
      toast.success(
        `Showing results based on filter ${searchType} : ${searchTerm}`
      );
      setFilteredPapers(filteredResult);
    } else {
      toast.error(
        `No result found with search ${searchType} : ${searchTerm} . So showing All results!`
      );
      setFilteredPapers(samplePapers);
    }

    setPaperCategory("");
    setPaperLevel("");
    setPaperType("");
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Asia/Kolkata",
  };

  return (
    <div>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div
            className="content"
            style={{ padding: "20px", background: "#F5F5F5" }}
          >
            <h6 id="sample">
              {" "}
              <span
                style={{
                  color: "#A4A4A4",
                  margin: "5px 0",
                  display: "inline-block",
                }}
              >
                Home
              </span>{" "}
              &gt;{" "}
              <span
                style={{
                  color: "black",
                  margin: "5px 0",
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                }}
              >
                Papers
              </span>{" "}
            </h6>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backdropState}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="container">
              <div className="search-center">
                <div className="category">Search By</div>

                <div className="feature-section">
                  <Box className="box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        PaperType
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paperType}
                        label="Age"
                        onChange={(e) => setPaperType(e.target.value)}
                      >
                        <MenuItem value={"sample"}>Sample Paper</MenuItem>
                        <MenuItem value={"final"}>Final Paper</MenuItem>
                        <MenuItem value={"all"}>All</MenuItem>
                      </Select>
                    </FormControl>
                    <button onClick={() => search(paperType, "paperType")}>
                      Search
                    </button>
                  </Box>
                  <Box className="box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paperCategory}
                        label="category"
                        onChange={(e) => setPaperCategory(e.target.value)}
                      >
                        <MenuItem value={"jr"}>Junior</MenuItem>
                        <MenuItem value={"sr"}>Senior</MenuItem>
                        <MenuItem value={"all"}>All</MenuItem>
                      </Select>
                    </FormControl>
                    <button onClick={() => search(paperCategory, "category")}>
                      Search
                    </button>
                  </Box>

                  <Box className="box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Paper level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paperLevel}
                        label="Student level"
                        onChange={(e) => setPaperLevel(e.target.value)}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={"all"}>All</MenuItem>
                      </Select>
                    </FormControl>
                    <button
                      onClick={() => search(paperLevel, "level")}
                      style={{
                        backgroundColor: "#5327b9",
                      }}
                    >
                      Search
                    </button>
                  </Box>
                </div>
              </div>

              <div className="table_wrapper">
                <table>
                  <thead>
                    <tr style={{ padding: "10px 0" }}>
                      <th>S.No</th>
                      <th>Paper Level</th>
                      <th>category</th>
                      <th>Paper Type</th>
                      <th>No.Of Question</th>
                      <th>Date Uploaded</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredPapers?.length ? (
                      filteredPapers.map((question, index) => {
                        return (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{question.level}</td>
                            <td>
                              {question.category === "sr" ? "Senior" : "Junior"}
                            </td>
                            <td>{question.paperType}</td>
                            <td>{question.questions.length}</td>
                            <td>
                              {new Date(
                                question.dateUploaded
                              ).toLocaleDateString("en-US", options)}{" "}
                            </td>
                            <td style={{ display: "flex" }}>
                              <button
                                style={{
                                  width: "100px",
                                  borderRadius: "8px",
                                  margin: "4px 10px",
                                  border: "1px solid",
                                  background: "#5327b9",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  jumpToQuestions(
                                    question.questions,
                                    question._id
                                  )
                                }
                              >
                                View
                              </button>
                              <button
                                style={{
                                  width: "100px",
                                  borderRadius: "8px",
                                  margin: "4px 0",
                                  border: "1px solid",
                                  background: "red",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => deletePaper(question._id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <span
                        style={{
                          display: "block",
                          marginTop: "20px",
                          color: "red",
                        }}
                      >
                        No Data For This Search !
                      </span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPapers;

import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import SchoolIcon from "@mui/icons-material/School";
import { useLocation,useParams } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BugReportIcon from '@mui/icons-material/BugReport';
import ErrorIcon from '@mui/icons-material/Error';

const Sidebar = () => {
  const location = useLocation();
  const { dispatch } = useContext(DarkModeContext);


  return (
    <div className="sidebar">
      <div className="top">
        <Link className="logo" to="/" style={{ textDecoration: "none" }}>
          <img src={Logo} alt="" />
        </Link>
        <h5 style={{textTransform:"uppercase",fontWeight:"bolder"}}>Admin Panel</h5>
      </div>
      {/* <hr /> */}
      <div className="center">
        <ul>
          {/* <p className="title">MAIN</p> */}

          <Link to="/" style={{ textDecoration: "none" }}>
            <li
              style={{
                background: location.pathname === "/" ? "rgb(231 220 220)" : "",
              }}
            >
              <FileUploadIcon className="icon" />
              <span>upload questions</span>
            </li>
          </Link>

          <Link to="/question-papers" style={{ textDecoration: "none" }}>
            <li
              style={{
                background:
                  location.pathname === "/question-papers" ? "rgb(231 220 220)" : "",
              }}
            >
              <MenuBookIcon className="icon" />
              <span>Question Papers</span>
            </li>
          </Link>

          <Link to="/students" style={{ textDecoration: "none" }}>
            <li
              style={{
                background:
                  location.pathname === "/students" ? "rgb(231 220 220)" : "",
              }}
            >
              <SchoolIcon className="icon" />
              <span>Students</span>
            </li>
          </Link>
          <Link to={`/student/final-result`} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/student/final-result" ? "rgb(231 220 220)" : "",
              }}
            >
              <ReceiptLongIcon className="icon" />
              <span>Final Result</span>
            </li>
          </Link>
          <Link to={`/student/payments`} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/student/payments" ? "rgb(231 220 220)" : "",
              }}
            >
              <CurrencyRupeeIcon className="icon" />
              <span>Payments</span>
            </li>
          </Link>
          <Link to={"/student/all-issues"} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/student/all-issues" ? "rgb(231 220 220)" : "",
              }}
            >
              <BugReportIcon className="icon" />
              <span>All Issues</span>
            </li>
          </Link>

          <Link to={"/all/wrong/questions"} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/all/wrong/questions" ? "rgb(231 220 220)" : "",
              }}
            >
              <ErrorIcon className="icon" />
              <span>Wrong Questions</span>
            </li>
          </Link>
          
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;


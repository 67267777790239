import Home from "./pages/home/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionPapers from "./pages/Question papers/QuestionPapers.js";
import SamplePaper from "./components/samplePapersPage/SamplePaper";
import Students from "./pages/All students/Students";
import StudentProgress from "./components/Student Progess/StudentProgress";
import FinalResult from "./pages/Final Result/FinalResult";
import ProtectedRoute from "./components/protected Route/ProtectedRoutes";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import Payments from "./pages/Payments/Payments";
import Issues from "./pages/Issues/Issues";
import IssueCards from "./pages/Issue Cards/IssueCards";
import WrongQuestions from "./pages/Wrong Questions/WrongQuestions";


function App() {



  const auth = JSON.parse(window.localStorage.getItem("auth"));
  // console.log(auth.name)

  


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}

          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/question-papers" element={<QuestionPapers />} />
            <Route path="/students" element={<Students />} />
            <Route path="/paper/:paperId" element={<SamplePaper />} />
            <Route
              path="/student/progress/:studentId"
              element={<StudentProgress />}
            />
            <Route path="/student/final-result" element={<FinalResult />} />
            <Route path="/student/payments" element={<Payments />} />
            <Route path="/student/all-issues" element={<Issues />} />
            <Route path="/student/issue/:studentId" element={<IssueCards />} />
            <Route path="/all/wrong/questions" element={<WrongQuestions />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

export default App;

